<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="5"
      >
        <b-card title="Preencha todos os campos para cadastrar seu BOT 🚀">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="createBot">
              <b-row>
                <!-- NOME DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Numero do Bot"
                    label-for="name-grupo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Numero do Bot"
                      rules="required"
                    >
                      <b-form-input
                        id="name-group"
                        v-model="numero"
                        :state="errors.length > 0 ? false:null"
                        placeholder="(XX) XXXXX-XXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- LINK DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Valor do Bot"
                    label-for="link-grupo"
                  >
                    <validation-provider
                      name="Link do Grupo"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="valor"
                        type="number"
                        placeholder="VALOR DO BOT"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- CODIGO DO GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Dono"
                    label-for="link-group"
                  >
                    <validation-provider
                      name="Dono"
                      rules="required"
                    >
                      <b-form-input
                        id="link-group"
                        v-model="dono"
                        placeholder="DONO DO BOT"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- login button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="createBot"
                  >
                    Salvar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const user = JSON.parse(localStorage.getItem('admin'))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      numero: '',
      valor: 10,
      dono: '',
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
    },

    createBot() {
      const body = {
        reseller_id: user.id, number: this.numero, value: this.valor,
      }
      funcs.createBot(body).then(success => {
        console.log(success)
        if (success.data.error === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: success.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (success.code === 'ER_DUP_ENTRY') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Já existe cadastro desse grupo',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Verifique os campos digitados!',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
